var broadcast = function (pusher_endpoint) {


    var broadcast = this;

    if(typeof io != 'undefined') {
        window.Echo = new Echo({
            broadcaster: 'socket.io',
            host: pusher_endpoint
        });

        Echo.private('domain')
            .listen('DomainStatusChange', function (data) {
                broadcast.updateDomains(data);
            });
    }

    this.updateDomains = function (data) {
        // estraggo i dati che mi sono arrivati dal server
        var domain = data.domain;
        var count = data.count;

        // recupero il dropdown modal
        var $modal = $("#domain-dropdown-modal");

        // aggiorno il contatore
        $modal.find('.domain-count').html(count);

        // aggiorno la lista e le percentuali dei domini in corso di nuvolarizzazione
        var $list = $modal.find('.domain-list');

        // elemento che rappresenta il dominio e il suo stato
        var $domain = null;
        if ($list.find('li[data-id=' + domain.id + ']').length > 0) {
            // aggiorno elemento già esistente
            $domain = $list.find('li[data-id=' + domain.id + ']');
            $domain.find('.domain-name').html(domain.name);
            $domain.find('.domain-percentage').html(domain.nuvolarization_percentage + '%');
            $domain.find('.domain-progress').css('width', domain.nuvolarization_percentage + '%');
        } else {
            // aggiungo elemento non ancora esistente
            $domain = $('<li data-id="' + domain.id + '">' +
                '<a href="#">' +
                '<div class="clearfix">' +
                '<span class="pull-left domain-name">' + domain.name + '</span>' +
                '<span class="pull-right domain-percentage">' + domain.nuvolarization_percentage + '%</span>' +
                '</div>' +
                '<div class="progress progress-mini">' +
                '<div style="width: ' + domain.nuvolarization_percentage + '%" class="progress-bar domain-progress"></div>' +
                '</div>' +
                '</a>' +
                '</li>');

            $list.append($domain);
        }

        // mostro in tutti i casi il modal
        $modal.show();

        // aggiorno la datatable dei domini se sono nella pagina
        if ($("#tblDomain_default").length > 0) {
            $("#tblDomain_default").data('dataTable').redrawPage();
        }
    };

};