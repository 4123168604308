var content_upload = function () {
    var content_upload = this;

    this.getSlugifiedUniqueFromFile = function (file) {
        return Slugify.parse("_" + file.name + file.lastModified + file.size);
    };

    this.addNewFile = function (file) {
        $("#elenco_file_container").show();

        var nuovaTr = $("#elenco_file .template").clone();

        nuovaTr.removeClass("template");
        nuovaTr.show();
        nuovaTr.addClass('warning');
        nuovaTr.attr("id", content_upload.getSlugifiedUniqueFromFile(file));

        nuovaTr.find("[data-interaction=filename]").html(file.name);

        $("#elenco_file").append(nuovaTr);
    };

    this.parseUploadResult = function (objfile, data) {
        var riga = $("#elenco_file").find("#" + content_upload.getSlugifiedUniqueFromFile(objfile));
        riga.find(".loader").detach();
        riga.removeClass('warning');
        if (data.response) {
            riga.addClass('success');
            riga.find("[data-interaction=filename]").html(data.title);
            riga.attr("id", '');
            riga.attr("data-id", data.id);
        } else {
            riga.addClass('danger');
        }

        $.each(data.message, function (index, obj) {
            var curElem = riga.find('[data-interaction=' + index + ']');
            var status = "<i class='fa fa-check-circle green'>";
            if (!obj.response) {
                status = "<i class='fa fa-exclamation-triangle red'>";
            }
            curElem.find("[data-interaction=status]").html(status);
            curElem.find("[data-interaction=message]").html(obj.message);
        });
    };

    this.init = function (domainName) {

        var myDropzone = $("#dropzone").dropzone({
            headers: $.ajaxSetup().headers,
            paramName: "file", // The name that will be used to transfer the file
            maxFilesize: 500, // MB
            addRemoveLinks: false,
            dictDefaultMessage: '<span class="bigger-150 bolder"><i class="ace-icon fa fa-caret-right red"></i> ' + domainName + '</span>  \
                    <br /> \
                    <i class="upload-icon ace-icon fa fa-cloud-upload blue fa-5x"></i>'
            ,
            dictResponseError: 'Errore durante l\'upload!',
            previewTemplate: "<div class=\"dz-preview dz-file-preview\">\n  <div class=\"dz-details\">\n    <div class=\"dz-filename\"><span data-dz-name></span></div> <div class=\"dz-size\" data-dz-size></div>\n    <img data-dz-thumbnail />\n  </div>\n  <div class=\"progress progress-small progress-striped active\"><div class=\"progress-bar progress-bar-success\" data-dz-uploadprogress></div></div>\n  <span data-dz-errormessage></span></div>\n</div>", // <div class=\"dz-success-mark\"><span></span></div>\n  <div class=\"dz-error-mark\"><span></span></div>\n  <div class=\"dz-error-message\">
            accept: function (objfile, done) {
                content_upload.addNewFile(objfile);
                done();
            },
            sending: function (file, xhr, formData) {
                $.each($("#content_categories").select2("data"), function (index, obj) {
                    formData.append('categories[]', obj.id);
                });
                $.each($("[name=content_tags_name]"), function (index, obj) {
                    formData.append('tags_name[]', $(obj).val());
                });
                $.each($("[name=content_tags_value]"), function (index, obj) {
                    formData.append('tags_value[]', $(obj).val());
                });
            },
            success: function (file, data) { // data contiene la risposta del server
                content_upload.parseUploadResult(file, data);
            }
        });

        $(document).on('ajaxloadstart.page', function (e) {
            try {
                myDropzone.destroy();
            } catch (e) {
            }
        });

    }

}